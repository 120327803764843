import React, { useState } from 'react';
import './App.css';
import Router from './Router';
import Loader from './components/Loader';
import Snackbar from './components/Snackbar';

export const GeneralContext = React.createContext();

function App() {
    const [isLoader, setIsLoader] = useState(true);
    const [snackbarText, setSnackbarText] = useState('');
    const [token, setToken] = useState(localStorage.token);
    const [tokenInput, setTokenInput] = useState('');

    const snackbar = text => {
        setSnackbarText(text);
        setTimeout(() => setSnackbarText(''), 3 * 1000);
    }

    const selectToken = () => {
        localStorage.token = tokenInput;
        setToken(tokenInput);
    }

    const clearToken = () => {
        setTokenInput(token);
        setToken('');
        localStorage.removeItem('token');
    }

    return (
        <GeneralContext.Provider value={{ setIsLoader, snackbar, token }}>
            {
                token ?
                <div className="App">
                    <button className='clearToken' onClick={clearToken}>מחק Token</button>
                    <h1>אתר חדשות שלי</h1>
                    <Router />
                    {isLoader && <Loader />}
                    {snackbarText && <Snackbar text={snackbarText} />}
                </div> :
                <div className="App">
                    <h1>נא להזין Token</h1>
                    <div className='tokenFrame'>
                        <input type="text" value={tokenInput} onInput={ev => setTokenInput(ev.target.value)} />
                        <button disabled={!tokenInput} onClick={selectToken}>אישור</button>
                    </div>
                </div>
            }
        </GeneralContext.Provider>
    );
}

export default App;
